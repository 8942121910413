<template>
  <svg
    width="37"
    height="37"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.5"
      y="1.5"
      width="77"
      height="77"
      rx="38.5"
      :stroke="isActive ? '#00802F' : '#00A03B'"
      stroke-width="3"
    />
    <path
      d="M24.3499 58C23.6499 58.1667 23.0416 57.9917 22.5249 57.475C22.0083 56.9583 21.8333 56.35 21.9999 55.65L23.9999 46.1L33.8999 56L24.3499 58ZM33.8999 56L23.9999 46.1L46.8999 23.2C47.6666 22.4333 48.6166 22.05 49.7499 22.05C50.8833 22.05 51.8333 22.4333 52.5999 23.2L56.7999 27.4C57.5666 28.1667 57.9499 29.1167 57.9499 30.25C57.9499 31.3833 57.5666 32.3333 56.7999 33.1L33.8999 56ZM49.7499 26L29.0499 46.7L33.2999 50.95L53.9999 30.25L49.7499 26Z"
      :fill="isActive ? '#00802F' : '#00A03B'"
    />
  </svg>
</template>

<script>
import { isReactive } from "vue";

export default {
  name: "PenIco",
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
